import {
    BrowserRouter,
    Routes,
    Route,
    useSearchParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyDetails from "./containers/VerifyDetails";
import Home from "./containers/Home";
import PanDetails from "./containers/PanDetails";
import LivenessCheck from "./containers/LivenessCheck";
import { createContext, useEffect, useState } from "react";
import { getCoApplicantDetails } from "./apis/coApplicant";
import { useLocation } from "react-router";
import CoApplicantDocuments from "./containers/CoApplicantDocuments";

export const MainContext = createContext<any>("lll");

const Router = () => {
    const [coApplicantDetails, setCoApplicantDetails] = useState<any>(null);
    useEffect(() => {
        (async () => {
            let response = await getCoApplicantDetails();
            response = {
                ...response?.loanApplicantsdata,
                loanAmount: parseFloat(
                    response?.loanApplicantsdata?.loanAmount
                ).toFixed(2),
                aadhaarName: response?.loanApplicantsdata?.aadhaarDetails
                    ? response?.loanApplicantsdata?.aadhaarName
                    : response?.loanApplicantsdata.maainApplicantName?.name,
                entityNamae: response?.loanApplicantsdata?.entityNamae,
            };
            if (response) {
                setCoApplicantDetails(response);
            }
        })();
    }, []);

    return (
        <div className="text-sm">
            <MainContext.Provider
                value={{ coApplicantDetails, setCoApplicantDetails }}
            >
                <ToastContainer limit={4} />
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route
                            path="/verify-details"
                            element={<VerifyDetails />}
                        />
                        <Route path="/pan-details" element={<PanDetails />} />
                        <Route
                            path="/liveness-check"
                            element={<LivenessCheck />}
                        />
                        <Route
                            path="/co-applicant-documents"
                            element={<CoApplicantDocuments />}
                        />
                    </Routes>
                </BrowserRouter>
            </MainContext.Provider>
        </div>
    );
};

export default Router;
